<template>
  <div class="w-full  h-full md:h-44 text-center align-middle bg-gris border-y-2 border-gris md:grid-cols-4 flex flex-col md:grid px-14 lg:px-44" ref="chiffres">
    <div class="my-12 md:my-0 row-span-1 md:col-span-1 bg-gris self-center font-inter ">
      <p class="font-bold text-6xl text-gradient-gray"><Number :from="0" :to="300" :duration="3" :delay="0.2" easing="Power4.easeOut" v-if="displayChiffres"></Number>
        <Number v-else :from="0" :to="0">0</Number></p>
      <p class="font-bold text-lg text-gris">INTERVENANTS POTENTIELS</p>
    </div>
    <div class="mb-12 md:mb-0 row-span-1 md:col-span-1 bg-gris self-center font-inter md:border-l-[1px] md:border-gris">
      <p class="font-bold text-6xl text-gradient-gray"><Number :from="0" :to="29" :duration="3" :delay="0.2" easing="Power4.easeOut" v-if="displayChiffres"></Number>
        <Number v-else :from="0" :to="0">0</Number></p>

      <p class="font-bold text-lg text-gris">ANS<br class="block 2xl:hidden"> D'ÂGE</p>

    </div>
    <div class="mb-12 md:mb-0 row-span-1 md:col-span-1 bg-gris self-center font-inter md:border-l-[1px] md:border-gris">
      <p class="font-bold text-6xl text-gradient-gray"><Number :from="0" :to="5" :duration="3" :delay="0.2" easing="Power4.easeOut" v-if="displayChiffres"></Number>
        <Number v-else :from="0" :to="0">0</Number></p>
      <p class="font-bold text-lg text-gris">DOMAINES D'EXPERTISE</p>
    </div>
    <div class="mb-12 md:mb-0 row-span-1 md:col-span-1 bg-gris self-center font-inter md:border-l-[1px] md:border-gris">
      <p class="font-bold text-6xl text-gradient-gray" ><Number :from="0" :to="5" :duration="3" :delay="0.2" easing="Power4.easeOut" v-if="displayChiffres"></Number>
        <Number v-else :from="0" :to="0">0</Number></p>
      <p class="font-bold text-lg text-gris">INGENIEURS BAC+5</p>
      <div class="vertical-line"></div>
    </div>
  </div>
</template>

<script>

import Number from 'vue-number-animation/Number.vue';

export default {
    data() {
        return {
            displayChiffres: false,
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.displayChiffres = this.isInViewport('chiffres') || this.displayChiffres
        },
        
        isInViewport(ref) {
            const rect = this.$refs[ref].getBoundingClientRect()
            var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            if (width<=900) {return true}
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    },

    components: { Number }
}
</script>

<style>

.text-gradient-gray {
    background-image: linear-gradient(180deg, rgb(255, 255, 255), rgb(148,163,184));
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gris {
    background-color: #111111;
}

.border-gris {
    border-color: #333333;
}

.text-gris {
  color:rgb(148,163,184);
}

</style>