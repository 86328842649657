<template>
  <div class="md:mb-32 mb-10 md:mt-44 mt-32">
    <h1 class="text-center text-white font-extrabold text-5xl  ">Nous contacter</h1>
    <hr class="w-48 h-1 mx-auto mt-4 bg-blue-base border-0 rounded md:mt-10">
  </div>
  <div class="flex justify-center w-full max-w-full md:max-w-[80%] m-auto my-20">
    <div class="relative bg-white rounded-xl">
      <div class="absolute inset-0 rounded-xl">
        <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-xl"/>
      </div>
      <div class="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div style="background-color: #e0e0e0"
             class=" rounded-t-xl lg:rounded-tr-none lg:rounded-l-xl py-16 w-full px-20 lg:px-24 xl:px-28 2xl:px-36 lg:col-span-2 lg:py-24 ">
          <div class="mx-auto max-w-lg ">
            <div class="text-center justify-center flex">
              <img alt="logo de TNS" src="@/assets/tns_logo_icon_couleur_fond_transparent_72dpi.png">
            </div>
            <dl class="mt-8 text-base text-gray-600 font-inter font-bold flex flex-col items-center">
              <div>
                <dt class="sr-only">Adresse</dt>
                <dd>
                  <p>193, avenue Paul Muller</p>
                  <p>54602 Villers-lès-Nancy</p>
                </dd>
              </div>
              <div class="mt-6">
                <dt class="sr-only">Numéro</dt>
                <dd class="flex">
                  <PhoneIcon class="h-6 w-6 flex-shrink-0 text-gray-400 text-blue-base" aria-hidden="true"/>
                  <a class="ml-3 hover:text-gray-800" href="tel:0372745929">+33 (0)3 72 74 59 29</a>
                </dd>
              </div>
              <div class="mt-3">
                <dt class="sr-only">Email</dt>
                <dd class="flex">
                  <EnvelopeIcon class="h-6 w-6 flex-shrink-0 text-gray-400 text-blue-base" aria-hidden="true"/>
                  <a class="ml-3 hover:text-gray-800" href="mailto:contact@tnservices.fr">contact@tnservices.fr</a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div id="form" class="bg-white py-16 px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12 rounded-xl">
          <div class="mx-auto max-w-lg lg:max-w-none">
            <form method="POST" action="https://formsubmit.co/b593bf1f4898c9a9fa97e5800b1ef071" class="grid grid-cols-1 gap-y-6">
              <input type="hidden" name="_next" value="https://tnservices.fr/merci">
              <input type="hidden" name="_captcha" value="true">
              <input type="hidden" name="_subject" value="Nouveau message depuis tnservices.fr !">
              <div>
                <label for="nom" class="sr-only">Nom</label>
                <input v-model="nom" type="text" name="nom" id="nom" required autocomplete="name"
                       style="border-width: 1px;"
                       class="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 focus:border-blue-base focus:ring-blue-base"
                       placeholder="Nom"/>
              </div>
              <div>
                <label for="email" class="sr-only">Email</label>
                <input v-model="email" id="email" name="email" type="email" required autocomplete="email"
                       style="border-width: 1px;"
                       class="block w-full rounded-md  border-gray-300 py-3 px-4 placeholder-gray-500 focus:border-blue-base focus:ring-blue-base"
                       placeholder="Email"/>
              </div>
              <div>
                <label for="tel" class="sr-only">Téléphone</label>
                <input v-model="tel" type="text" required name="tel"
                       id="tel" autocomplete="tel" style="border-width: 1px;"
                       class="block w-full rounded-md  border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-blue-base focus:ring-blue-base"
                       placeholder="Téléphone"/>
              </div>
              <div>
                <label for="message" class="sr-only">Message</label>
                <textarea v-model="content" id="message" name="content" rows="4" required
                          style="border-width: 1px;"
                          class="block w-full rounded-md  border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-blue-base focus:ring-blue-base"
                          placeholder="Écrivez votre message"/>
              </div>
              <div>
                <p v-if="error.champs" class="mb-5 text-red-600 font-inter font-bold">Veuillez remplir l'ensemble
                  des champs.</p>
                <p v-if="error.email" class="mb-5 text-red-600 font-inter font-bold">Veuillez rentrer un mail
                  correct.</p>
                <p v-if="error.send" class="mb-5 text-red-600 font-inter font-bold">Un problème est survenu lors de
                  l'envoi du mail.</p>
                <button type="submit" 
                        class="inline-flex justify-center rounded-md border border-transparent bg-blue-base py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-blue-base-hover focus:outline-none focus:ring-2 focus:ring-blue-base-hover focus:ring-offset-2">
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
        <div id="check" class="hidden bg-white py-16 px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12 rounded-xl">
          <div class="mx-auto max-w-lg lg:max-w-none">
            <div class="flex justify-center">
              <div class="flex justify-center flex-col">
                <lottie-animation path="../assets/images/checkmark.json" class="text-blue-base" :autoPlay="true"
                                  :loop="true" :width="256" :height="256"/>
                <div class="text-center">
                  <h2 class="text-2xl font-bold text-gray-900">Merci !</h2>
                  <p class="text-gray-600">Votre message a bien été envoyé.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {EnvelopeIcon, PhoneIcon} from '@heroicons/vue/24/outline'
</script>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import ContactForm from '@/components/ContactForm.vue';

export default {
  name: 'Contact',
  components: {
    LottieAnimation
  },
  data() {
    return {
      nom: '',
      email: '',
      tel: '',
      content: '',
      error: {
        champs: false,
        email: false
      }
    }
  },
  methods: {
    onSubmit() {
      this.email = document.getElementById('email').value;
      this.nom = document.getElementById('nom').value;
      this.tel = document.getElementById('tel').value;
      this.content = document.getElementById('message').value;

      // mettre tous les éléments du tableau error à false
      Object.keys(this.error).forEach(key => this.error[key] = false);

      if (!this.email.includes('@')) {
        this.error.email = true;
        return;
      }
      if (this.nom === '' || this.email === '' || this.tel === '' || this.content === '') {
        this.error.champs = true;
        return;
      }

      fetch('https://formsubmit.co/b593bf1f4898c9a9fa97e5800b1ef071', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          nom: this.nom,
          email: this.email,
          tel: this.tel,
          content: this.content
        })
      }).then(response => {
        document.getElementById('form').classList.add('hidden');
        document.getElementById('check').classList.remove('hidden');
      }, response => {
        this.error.send = true;
      });
    }
  },
  mounted() {
    // scroll to top
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
</script>