<template>
  <div class="opacity-50">
    <svg class="absolute right-full hidden translate-x-1/2 translate-y-[900px] transform lg:block" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" class="text-blue-base" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect width="404" height="900" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"></rect>
    </svg>
  </div>
  <div>
    <div class="md:mb-32 mb-4 md:mt-60 mt-32">
      <h1 class="text-center text-white font-extrabold text-6xl md:text-8xl font-inter relative z-40">TELECOM NANCY <br><span class="text-blue-base">SERVICES</span></h1>
      <div class="inline-flex items-center justify-center w-full">
        <hr class="w-28 h-1 my-8 bg-blue-base border-0 mr-16 rounded ">
        <div class="absolute px-4 -translate-x-1/2 bg-transparent left-1/2">
          <img src="@/assets/tns_logo_icon_couleur.svg" class="h-10 w-10">
        </div>
        <hr class="w-28 h-1 my-8 bg-blue-base border-0 rounded ">
      </div>
    </div>

    <MotDirecteur></MotDirecteur>

    <CarouselServices></CarouselServices>
    <!-- <Prestations></Prestations> -->
    <Chiffres></Chiffres>
    <Partenaires></Partenaires>


    <div class="w-full bg-black-base h-full py-24 flex">
      <div class="text-center justify-center mx-auto w-[80%]">
        <div class="w-full h-full">
          <iframe class="rounded-xl" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Découvrez l'Envers du Campus de Telecom Nancy" src="https://www.youtube.com/embed/6GUcNXqEzNc?controls=0&amp;rel=0&amp;disablekb=1&amp;showinfo=0&amp;modestbranding=0&amp;html5=1&amp;iv_load_policy=3&amp;autoplay=0&amp;end=0&amp;loop=0&amp;playsinline=0&amp;start=0&amp;nocookie=false&amp;enablejsapi=1&amp;origin=https%3A%2F%2Ftnservices.fr&amp;widgetid=1"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Presentation from '@/components/Accueil/Presentation.vue'
import Chiffres from '@/components/Accueil/Chiffres.vue'
import Partenaires from '@/components/Accueil/Partenaires.vue';
import Prestations from './Prestations.vue'
import CarouselServices from '../components/Accueil/CarouselServices.vue'
import MotDirecteur from "@/components/Accueil/MotDirecteur.vue";

export default {
  name: 'Accueil',
  components: {
    MotDirecteur,
    Presentation,
    Chiffres,
    Partenaires,
    Prestations,
    CarouselServices
  },
  mounted() {
    // scroll to top
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
</script>

<style>

iframe {
  aspect-ratio: 16/9;
  width: 100%;
  /* max-width: 600px; */
}

</style>
