import { Vue, createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueNumber from "vue-number-animation";
import './assets/css/in.css'

const app = createApp(App)
app.use(router)
app.use(VueNumber)
app.mount('#app')
