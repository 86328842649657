<template>
<div>
    <h1 class="text-center text-white font-bold text-3xl mb-12 mt-16">Les membres de l'équipe TNS</h1>
    <hr class="w-48 h-1 mx-auto my-4 bg-blue-base border-0 rounded md:my-10">
</div>
    <!-- Les membres s'affichent dans l'ordre du fichier membres.json -->
    <div v-if="members.length" class="container-members">
        <div v-for="member in members" :key="member.id">
            <Membre :membre="member"></Membre>
        </div>
    </div>
    <div v-else>
        <h1>Chargement des membres...</h1> <!--TODO-->
    </div>
</template>

<script>
import Membre from './Membre.vue'
import Membres from '@/../data/membres.json'
export default {
    data() {
        return {
            members: Membres
        };
    },
    // mounted() {
    //     fetch("http://localhost:3000/members")
    //         .then(res => res.json())
    //         .then(data => this.members = data)
    //         .catch(err => console.log(err.message));
    // },
    components: { Membre }
}
</script>

<style> 
    .container-members {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;
    }
</style>