<template>
  <div class="bg-transparent">
    <div class="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
      <div class="relative isolate overflow-hidden bg-gris px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
        <figure class="max-w-screen-md mx-auto text-center">
          <svg aria-hidden="true" class="w-12 h-12 mx-auto mb-3 text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/></svg>
          <blockquote>
            <p class="text-2xl italic font-medium text-white">Portée par des futurs ingénieurs en sciences du numérique, Telecom Nancy Services concentre les talents développés dans notre école.
              La Junior Conseil propose des services de haut niveau construits sur les piliers de la formation dispensée à Télécom Nancy.</p>
          </blockquote>
          <figcaption class="flex items-center justify-center mt-6 space-x-3">
            <img class="w-12 h-12 rounded-full" src="@/assets/Directeur.jpg" alt="" />
            <div class="flex items-center divide-x-2 divide-gray-700">
              <cite class="pr-3 font-medium text-white">Pierre-Etienne MOREAU</cite>
              <cite class="pl-3 text-sm font-light text-gray-400">Directeur de TELECOM NANCY</cite>
            </div>
          </figcaption>
        </figure>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" class="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2" aria-hidden="true">
          <circle cx="512" cy="512" r="512" fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fill-opacity="0.7" />
          <defs>
            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(512 512) rotate(90) scale(512)">
              <stop stop-color="#2255ff" />
              <stop offset="1" stop-color="#2255ff" stop-opacity="0"></stop>
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MotDirecteur"
}
</script>

<style scoped>
.bg-gris {
  background-color: #111111;
}
</style>