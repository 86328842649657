<template>
  <div class="opacity-50">
    <svg class="absolute right-full hidden translate-x-1/2 translate-y-[400px] transform lg:block" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" class="text-blue-base" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect width="404" height="600" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"></rect>
    </svg>
  </div>
  <div class="md:mb-32 mb-10 md:mt-44 mt-32">
    <h1 class="text-center text-white font-extrabold text-5xl  ">Vous souhaitez rejoindre TNS ?</h1>
    <hr class="w-48 h-1 mx-auto mt-4 bg-blue-base border-0 rounded md:mt-10">
  </div>

  <div class="mb-24">
    <div class="relative mx-auto max-w-[80%] px-0 lg:px-8">
      <div class="relative overflow-hidden rounded-xl bg-blue-base py-24 px-8 shadow-2xl lg:grid lg:grid-cols-5 lg:gap-x-8 lg:px-16">
        <div class="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
          <img src="@/assets/telecom_nancy_tns.jpg" alt="" class="h-full w-full object-cover" />
        </div>
        <div class="col-span-1"></div>
        <div class="relative lg:col-span-3">
          <blockquote class="text-white">
            <img class="h-20 w-auto text-center justify-center m-auto mb-10" src="@/assets/tns_logo_icon_blanc_fond_transparent_72dpi.png" alt="" />
            <p class="text-xl font-medium text-center md:text-justify sm:text-2xl mb-10">
              Telecom Nancy Services est une <b>association de loi 1901 à but non lucratif</b> dirigée par les <b>étudiants</b> de l'école d'ingénieurs <b>TELECOM Nancy</b>, qui en sont les seuls membres.
            </p>
            <h2 class="text-center text-white font-bold text-2xl">Élève à TELECOM Nancy ?</h2>
            <hr class="w-32 h-1 mx-auto mt-4 bg-white border-0 rounded mb-10">
            <p class="text-center md:text-justify">Dans ce cas, vous pouvez directement nous <a href="/contact"><b>contacter</b></a> et postuler à un poste qui vous intéresse, vous êtes le bienvenu ! <br>
              Participer à l'aventure TNS est une opportunité de renforcer son expérience et ses compétences.<br><br>
              Si vous ne souhaitez pas devenir membre mais participer tout de même à des projets informatiques, vous pouvez répondre à une mission en nous rejoingnant sur le <a href="https://discord.gg/CEhkEgfsQX"><b> Pool de Dev discord.  </b></a></p><br>
            
              
              
          </blockquote>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    mounted() {
      // scroll to top
      window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    }
  }
</script>
  
<style scoped>
  p {
    font-size: 22px;
    color: #c3c3c3;
  }
  b {
    font-size: 22px;
    font-weight: bolder;
    color: white;
  }

  .content {
    flex-direction: column;
    align-content: center;
    background: rgb(100, 100, 100, 0.5);
    backdrop-filter: blur(3px);
    border-radius: 10px;
  }
</style>