<template>
    <div class="expertise">
      <img class="w-full h-full expertise rounded-3xl " :src="getPicture(expertise.image)">
      <h1 class="absolute font-inter font-bold md:text-5xl mx-10">{{expertise.title}}</h1>
        <!-- <p>{{expertise.content}}</p> -->
        <!-- <img :src="getPicture(expertise.image)"> -->
    </div>
</template>

<script>
export default {
    props: ['expertise'],
    methods: {
        getPicture(image) {
            try {
                return require("@/assets/illustrations/" + image)
            }
            catch (e) {
                return require("@/assets/defaultMemberIcon.png")
            }
        },
    }
}
</script>

<style scoped>

    .expertise {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: black;

        transition: all 0.2s ease;
        background-size: contain;        
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
        padding-left: 5px;
        padding-right: 5px;
    }

    h1 {
        color: white;
        user-select: none;
    }
</style>