<template>
  <div class="membre">
      <img :src="getPicture(membre.photo)">
      <p class="name">{{membre.prenom}} {{membre.nom}}</p>
      <p>{{membre.role}}</p>
      <a v-if="membre.link" :href="membre.link" target="_blank">
      <svg class="linkedin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
    </a>
  </div>
</template>

<script>
export default {
    props: ['membre'],
    methods: {
        getPicture(memberPhoto) {
            try {
                return require("@/assets/members/" + memberPhoto)
            }
            catch (e) {
                return require("@/assets/defaultMemberIcon.png")
            }
        }
    }
}
</script>

<style scoped>
    p {
        color: black;
    }

    .name {
        font-weight: 900;
        text-transform: uppercase;
    }
    .linkedin {
        width: 40px;
        border: none;
        border-radius: 0px;
        background: white;
    }
    .linkedin:hover {
        opacity: 50%;
        transition: all 0.2s ease;
    }
    .membre {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px;
        width: 200px;
        height: 250px;
        text-align: center;
        background: white;
        padding: 10px;
        border-radius: 10px;
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;
    }

    .membre:hover {
        box-shadow: 0px 1px 5px black;
    }

    img {
        width: 100px;
        border: none;
        background-color: white;
        align-self: auto;
        padding: 5px;
    }
    a {
        background: none;
    }

    p {
        background-color: white;
        padding: 5px;
    }
</style>