<template>
  <link href='https://fonts.googleapis.com/css?family=Raleway' rel='stylesheet'>
  <Navigation></Navigation>
  <br>
  <div class="content">
    <div class="z-0 opacity-50">
      <div>
        <svg class="absolute -right-[12rem] hidden -translate-x-1/2 -translate-y-1/4 transform lg:block" width="404" height="1400" fill="none" viewBox="-200 0 404 784" aria-hidden="true">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-blue-base" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>
      </div>
    </div>
    <router-view/>
  </div>
  <Footer></Footer>

</template>

<script>
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navigation,
    Footer,
  }
}
</script>


<style>


/**
:root {
  --background-color: #005c91;
  --background-color-transparent: rgba(23, 23, 23, 0.12);
  --vc-clr-primary: var(--background-color) !important;
  --vc-clr-secondary: rgb(180, 180, 180) !important;
  font-family: Raleway;
  background: linear-gradient(rgb(0 0 0 / 65%), rgba(0, 0, 0, 0.5)), url("@/assets/wallpaper.jpg") center center/cover no-repeat fixed;

}
*/

:root {
  background-color: #0b0b0b;
}

body {
  overflow-x: hidden;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
