<template>
  <header class="z-50 absolute">
    <nav class="navbar" :class="{'hidden-header' : hidden }">
      <router-link :to="{name: 'home'}"><img src="@/assets/tns_logo_complet_blanc_fond_transparent_300ppp.png" class="logo"></router-link>
      <div class="navlinks" :class="{ hamburger: hamburger}">
        <ul style="width: 100%" class="items-center">
          <li v-if="hamburger" class="pb-10">
            <div class="absolute px-4 -translate-x-1/2 bg-transparent left-1/2">
              <img src="@/assets/tns_logo_icon_couleur.svg" class="h-10 w-10">
            </div>
          </li>
          <li>
            <router-link @click.native="allowScrollY()" :to="{name: 'home'}" @click="closeHamburger">Accueil</router-link>
          </li>
          <li>
            <router-link @click.native="allowScrollY()" :to="{name: 'prestations'}" @click="closeHamburger">
              Prestations
            </router-link>
          </li>

          <li>
            <router-link @click.native="allowScrollY()" :to="{name: 'recrutement'}" @click="closeHamburger">Nous rejoindre</router-link>
          </li>
          <li>
            <router-link @click.native="allowScrollY()" :to="{name: 'about'}" @click="closeHamburger">À propos</router-link>
          </li>
          <li>
            <router-link @click.native="allowScrollY()" :to="{name: 'contact'}" @click="closeHamburger">Contact</router-link>
          </li>
        </ul>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="menu-hamburger" @click="toggleHamburger">
        <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/>
      </svg>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      hamburger: false,
      smallNavbar: false,
      hidden: false,
      lastScroll: 0,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 0) {
        if (window.scrollY < this.lastScroll) {
          this.hidden = false;
        } else {
          this.hidden = true;
        }
      } else {
        this.hidden = false;
      }
      this.lastScroll = window.scrollY;
    },
    toggleHamburger() {
      this.hamburger = !this.hamburger
      // desactiver overflow y quand l'hambuger est ouvert
      if (this.hamburger) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'auto'
      }
    },
    allowScrollY() {
      document.body.style.overflowY = 'auto'
    },
    closeHamburger() {
      this.hamburger = false
    },

  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

img {
  background: none;
  border: none;
}

.hidden-header {
  transform: translateY(-100%);
}

.navbar {
  position: fixed;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10px;
  box-sizing: border-box;
  background: rgba(23, 23, 23, 0.5);
  backdrop-filter: blur(3px);
  transition: all 300ms;
  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
}

.navbar .logo {
  opacity: 100%;
  width: 170px;
  border-radius: 0;
  background: none;
  transition: all 0.2s ease;
}

.navbar li a {
  background: none;
  color: white;
  opacity: 50%;
  font-size: 20px;
  font-weight: 800;
  transition: all 0.2s ease;
}

.navbar a:hover {
  opacity: 100%;
}

.navbar .navlinks ul {
  display: flex;
  background: none;
}

.navbar .navlinks ul li {
  background: none;
  margin: 25px;
}

nav li a.router-link-exact-active {
  opacity: 100%;
  background: none;
}

li {
  background: none;
}

.navbar .menu-hamburger {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 45px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(87deg) brightness(106%) contrast(102%);
}

@media screen and (max-width: 900px) {
  .navbar {
    padding: 0%;
    height: 70px;
  }

  .navlinks {
    top: 0%;
    left: 0%;
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: var(--background-color);
    margin-left: -100%;
    transition: all 0.5s ease;
  }

  .navlinks.hamburger {
    margin-left: 0;
    background-color: black;
  }

  .navlinks ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar .menu-hamburger {
    display: block;
  }

  .navbar .navlinks ul li {
    margin: 25px 0px;
  }

  .navbar .logo {
    /* position: absolute;
    top: 50px;
    left: 50px; */
    width: 110px;
    padding-left: 15px;
  }
}

:root {
  --background-color: #005c91;
  --background-color-transparent: rgba(23, 23, 23, 0.14);
  --vc-clr-primary: var(--background-color) !important;
  --vc-clr-secondary: rgb(180, 180, 180) !important;
  font-family: Raleway;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("@/assets/wallpaper.jpg") center center/cover no-repeat fixed;
  /* TODO: Image non libre de droits */
}

h1 {
  text-align: center;
  padding: 5px;
  color: white;
}

h1.title {
  padding: 50px;
}

h2 {
  text-align: center;
  color: rgb(159, 159, 159);
}

p {
  color: white;
}

.content {
  margin: 0px;
  margin-left: 0px;
  margin-top: 100px;
}

button {
  background: var(--background-color);
  border: 2px solid var(--background-color);
  padding: 10px 20px;
  margin-top: 20px;
  color: white;
  border-radius: 20px;
  transition: all 0.2s ease;
  font-family: Raleway;
  cursor: pointer;
}

button:hover {
  background: white;
  color: var(--background-color);
}

.center {
  text-align: center;
}

img {
  pointer-events: none;
  border-radius: 50%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>