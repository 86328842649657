<template>
  <div class="md:mb-24 mb-4 md:mt-24 mt-4">
    <h1 class="text-center text-white font-extrabold text-4xl  ">Nos domaines d'expertise</h1>
    <hr class="w-48 h-1 mx-auto mt-4 bg-blue-base border-0 rounded md:mt-10">
  </div>

    <div class="carousel mb-24 mt-24">
    <carousel :settings="settings" :breakpoints='breakpoints'>
        <slide v-for="expertise in expertises" :key="expertise.title">
        <Expertise :expertise="expertise"></Expertise>
        </slide>
        <template #addons>

            <pagination />
        </template>
    </carousel>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import Expertise from '../Prestations/Expertise.vue';
import Expertises from '@/../data/expertises.json'
export default {
    components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Expertise
},
    data() {
        return {
            settings: {
            itemsToShow: 2,
            snapAlign: "center",
            wrapAround: "true",
            autoplay: "5000",
            // pauseAutoplayOnHover: "true"
            },
            breakpoints: {
                900: {
                    itemsToShow: 1.5
                },
                1100: {
                    itemsToShow: 2.5
                },
                1300: {
                    itemsToShow: 3.5
                }
            },
            expertises: Expertises
        };
    },
}
</script>

<style>
    .carousel__prev {
        margin-left: 10px;
        transform: translate(0%, -50%) !important;
    }
    .carousel__next {
        margin-right: 10px;
        transform: translate(0%, -50%) !important;
    }
</style>