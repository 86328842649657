<template>
  <div class="container">
    <div class="video">
      <iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Découvrez l'Envers du Campus de Telecom Nancy" src="https://www.youtube.com/embed/6GUcNXqEzNc?controls=0&amp;rel=0&amp;disablekb=1&amp;showinfo=0&amp;modestbranding=0&amp;html5=1&amp;iv_load_policy=3&amp;autoplay=0&amp;end=0&amp;loop=0&amp;playsinline=0&amp;start=0&amp;nocookie=false&amp;enablejsapi=1&amp;origin=https%3A%2F%2Ftnservices.fr&amp;widgetid=1"></iframe>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin: 50px;
    flex-direction: row;
  }
  .video {
    width: 50%;
    /* aspect-ratio: 16/9; */
  }
  iframe {
    aspect-ratio: 16/9;
    width: 100%;
    /* max-width: 600px; */
  }
  .item {
    display: flex;
    flex-direction: column;
    text-align: center;
    /* justify-content: center; */
    /* width: 600px; */
    /* width: auto; */
    vertical-align: middle;
    width: 50%;
  }
  img {
    width: 150px;
    vertical-align: middle;

    /* border: 2px solid var(--background-color); */
  }


  @media screen and (max-width: 900px) {
    .container {
      flex-direction: column;
    }
    .video, .item {
      width: 100%;
    }
  }
</style>