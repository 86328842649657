<template>

  <div class="flex min-h-full flex-col bg-black-base pt-48 pb-60 z-50">
    <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
      <div class="flex flex-shrink-0 justify-center">
        <a href="/" class="inline-flex">
          <span class="sr-only">Your Company</span>
          <img class="h-24 w-auto" src="/assets/images/tns_logo_icon_couleur_fond_transparent_72dpi.png" alt="">
        </a>
      </div>
      <div class="py-16">
        <div class="text-center">
          <p class="text-base font-semibold text-blue-base"></p>
          <h1 class="mt-2 text-4xl font-bold tracking-tight text-blue-base sm:text-5xl">Merci pour votre message.</h1>
          <p class="mt-2 text-base text-gray-500">Nous vous répondrons dans les plus brefs délais.</p>
          <div class="mt-6">
            <button @click="$router.push({name: 'home'})" class="text-base font-medium text-blue-base hover:text-gray-500">
              Retourner à l'accueil
              <span aria-hidden="true"> &rarr;</span>
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>