<template>
  <div class="w-auto bg-gris border-y-2 border-gris overflow-x-hidden relative" id="app">
    <div class="container-bg">
      <div class="logo-container w-auto">
        <img
            class="h-10 w-auto logo transition-all duration-300"
            src="/assets/images/partenaires/universite.png" alt="img">
        <img v-for="img in listeImages" :key="img"
             class="h-10 w-auto logo filter invert grayscale hover:grayscale-0 hover:invert-0 transition-all duration-300"
             :src="'/assets/images/partenaires/' +img" alt="img">

        <img
            class="h-10 w-auto logo transition-all duration-300"
            src="/assets/images/partenaires/universite.png" alt="img">
        <img v-for="img in listeImages" :key="img"
             class="h-10 w-auto logo filter invert grayscale hover:grayscale-0 hover:invert-0 transition-all duration-300"
             :src="'/assets/images/partenaires/' +img" alt="img">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listeImages: [
        "ensgsi.png",
        "inria.png",
        "iae.png",
        "osul.png",
        "clinique_louis_pasteur.png",
        "rte.png",
        "societe_generale.png",
        "telecom.png",
        "transalliance.svg",
        "wig.png",
        "lcl.png",
      ]
    }
  }

}
</script>

<style scoped>

@media screen and (min-device-width: 601px) {
  .logo-container {
    display: flex;
    animation: scroll-logos infinite linear 80s;
  }

  @keyframes scroll-logos {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

@media screen and (max-device-width: 600px) {
  .logo-container {
    display: flex;
    animation: scroll-logos infinite linear 25s;
  }

  @keyframes scroll-logos {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-500%);
    }
  }
}

.logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-right: 1em;
  visibility: visible;
}


img {
  vertical-align: middle;
  border-radius: 0;
  border: none;
  margin: 50px;
  width: 200px;
}
</style>